// Reports

#admin-report-points {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	td {
		p {
			margin: 0px;
		}
	}
	.btn-settings {
		background-color: $blue !important;
		margin-bottom: 10px;
		border-radius: 10px;
		outline: 0px !important;
		box-shadow: none !important;
		min-width: 150px;
		font-size: 14px;
		font-family: $font-family-bold;
		color: $white !important;
	}
}

#admin-report-payments {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	td {
		p {
			margin: 0px;
		}
	}
}

.modal-points-settings {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-points-settings {
	padding: 20px;
	button[type="submit"] {
		font-family: $font-family-bold;
		background-color: $green_light !important;
		font-size: 20px;
		min-width: 250px;
		border-radius: 10px;
		padding: 10px;
		margin-top: 20px;
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.row-levels {
		p {
			margin: 0px;
			font-family: $font-family-bold;
			margin-top: 35px;
		}
	}
}