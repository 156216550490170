// Checkbox Table

.checkbox-table-component {
	width: 25px;
	height: 25px;
	border-radius: 5px;
	background-color: lighten($gray,10);
	border: 1px solid $gray2;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	&.active {
		background-color: $green;
	}
	img {
		width: 15px;
		height: 15px;
		object-fit: contain;
	}
	&.small {
		width: 20px;
		height: 20px;
	}
}