// Referral

#referral {
	.container {
		max-width: 900px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		min-height: 100vh;
		.image-invite {
			width: 300px;
			height: 300px;
			object-fit: contain;
			margin-top: 20px;
		}
		.btn-shop {
			img {
				width: 100%;
				max-width: 300px;
				height: 80px;
				object-fit: contain;
			}
		}
		h3 {
			font-family: $font-family-bold;
			font-size: 40px;
			margin-bottom: 40px;
			text-align: center;
		}
		p {
			font-size: 17px;
			strong {
				font-weight: normal;
				font-family: $font-family-bold;
			}
		}
	}
}