// San Structures

#san-structures {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-datepicker {
		margin-top: 5px;
	}
}