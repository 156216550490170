// Positions

#admin-positions {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	.status {
		font-family: $font-family-bold;
		cursor: pointer;
	}
	.link-assign {
		background-color: $green;
		border-radius: 5px;
		padding: 5px;
		text-decoration: none !important;
		color: $white !important;
		font-size: 11px;
	}
}

.modal-view-positions {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-view-positions {
	padding: 20px;
	.title-san {
		margin-bottom: 5px;
		font-size: 14px;
	}
	button[type="submit"] {
		font-family: $font-family-bold;
		background-color: $green_light !important;
		font-size: 20px;
		min-width: 250px;
		border-radius: 10px;
		padding: 15px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.container-positions {
		border-radius: 20px;
		border: 1px solid darken($gray4,20);
		width: 100%;
		display: flex;
		flex: 1;
		flex-direction: column;
		@include scroll();
		overflow-y: auto;
		&.gray {
			background-color: $gray;
		}
		.container-azar {
			flex: 1;
			@include scroll();
			overflow-y: auto;
		}
	}
	.btn-azar {
		font-family: $font-family-bold;
		background-color: $blue !important;
		font-size: 16px;
		width: 80%;
		margin: 0 auto;
		border-radius: 10px;
		padding: 10px;
		margin-top: 5px;
		margin-bottom: 10px;
	}
	.label {
		font-family: $font-family-bold;
		margin-bottom: 5px;
		font-size: 14px;
	}
	.container-modal-positions {
		display: flex;
		height: 350px;
		flex-direction: column;
	}
	.item-position {
		display: flex;
		justify-content: space-between;
		padding: 8px;
		padding-bottom: 4px;
		padding-top: 5px;
		&.active {
			background-color: $blue;
			color: $white;
			p {
				&.position {
					color: $green_light;
				}
			}
		}
		&.left:not(.active):hover {
			transition: .5s;
			background-color: darken($gray,5);
			cursor: pointer;
		}
		p {
			margin-bottom: 0px;
			font-size: 13px;
			&.position {
				color: $blue;
				margin-right: 5px;
			}
		}
		& > div {
			display: flex;
			flex: 1;
		}
	}
	.message {
		font-family: $font-family-bold;
		font-size: 14px;
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		color: $blue;
	}
	.link-icon {
		margin-right: 0px;
		margin-left: 5px;
		width: 15px;
		height: 15px;
		display: inline-block;
		margin-top: -4px;
		img {
			width: 15px;
			height: 15px;
			object-fit: contain;
		}
	}
	.btn-fill {
		font-family: $font-family-bold;
		background-color: $green_light !important;
		font-size: 16px;
		border-radius: 10px;
		padding: 15px;
		margin-top: 10px;
		color: $black !important;
		margin-bottom: 20px;
	}
}