// Header

#header {
	background-color: $gray;
	width: 100%;
	align-items: center;
	position: fixed;
	left: 0px;
	// @include shadow2();
	@media (max-width: 991px) {
		box-shadow: none !important;
	}
	.container-back {
		background-color: $prod-gray;
		height: $height-item;
		text-align: center;
		display: flex;
		align-items: center;
		p {
			text-transform: uppercase;
			width: 100%;
			img {
				width: 20px;
				height: 20px;
			}
		}
	}
	.container-titles {
		width: calc(100% - 300px);
		@media (max-width: 991px) {
			width: 100%;
			display: inline-flex;
		}
	}
	.container-back-top {
		flex: 1;
		padding-left: 20px;
		display: flex;
		align-items: center;
	}
	.container-blue-top {
		background-color: $white;
		height: $height-banner;
		display: flex;
		align-items: center;
		h3, h4 {
			color: $blue_dark;
			font-size: 22px;
			font-family: $font-family-bold;
			margin: 0px;
			padding: 0px;
			@media (max-width: 767px) {
				width: 100%;
			}
			@media (max-width: 991px) {
				font-size: 18px;
			}
		}
		h4 {
			font-size: 18px;
			color: $danger;
		}
		.container-user {
			@media (max-width: 600px) {
				p {
					display: none;
				}				
			}
		}
		p {
			font-family: $font-family;
			font-size: 12px;
			margin-bottom: 3px;
			color: $white;
		}
	}
	// &.header-responsive {
	// 	@media (max-width: 991px) {
	// 		margin-top: 54px;
	// 	}		
	// }
	z-index: 1001;
	transition: .6s;
	top: 0px;
	p {
		margin-bottom: 0px;
		font-size: 14px;
		font-family: $font-family-bold;
		@include ellipsis();
		width: 90%;
		position: relative;
	}
	.arrow-back {
		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			margin-right: 15px;
			cursor: pointer;
		}
	}
	.focusable-container {
		box-shadow: none !important;
		outline: 0px !important;
		border: 0px !important;
		background-color: transparent !important;
		padding: 0px !important;
		display: none;
		@media (max-width: 991px) {
			display: inline-block;
		}
	}
	ul {
		position: absolute;
		background-color: $white;
		min-width: 250px;
		overflow: hidden;
		right: 10px;
		top: 40px;
		list-style: none;
		padding: 0px;
		@include shadow();
		z-index: 999;
		border-radius: 10px;
		li {
			text-align: left;
			cursor: pointer;
			font-size: 16px;
			padding: 5px;
			padding-left: 15px;
			padding-right: 15px;
			img {
				width: 25px;
				height: 25px;
				margin-right: 15px;
			}
			&:hover {
				background-color: $prod-gray2;
			}
		}
	}
	.notification-icon {
		width: 30px;
		height: 30px;
		margin-top: 5px;
		margin-right: 10px;
		margin-left: 10px;
	}
	.container-user {
		display: flex;
		cursor: pointer;
		margin-right: 15px;
		border: 2px solid $white;
		border-radius: 30px;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
		img {
			width: 30px;
			height: 30px;
			margin-left: 10px;
		}
		i.fa {
			font-size: 25px;
			margin-right: 10px;
			margin-left: 15px;
			color: $white;
		}
		p {
			padding: 0px;
			margin: 0px;
			margin-left: 10px;
			color: $white;
			font-size: 16px;
			font-family: $font-family-bold;
		}
	}
	.container-buttons-top {
		display: flex;
		justify-content: space-between;
		position: absolute;
		right: 15px;
	}
	.container-buttons-web {
		display: flex;
		justify-content: center;
		a {
			text-decoration: none !important;
		}
		.container-settings {
			width: 30px;
			height: 30px;
			background-color: $white;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (min-width: 992px) {
				margin-left: 10px;
			}
		}
	}
	.navbar-toggler {
		background-color: $blue_dark;
		border-radius: 10px;
		height: auto !important;
		margin-top: 0px; 
		margin-right: 15px;
		padding-top: 10px;
		padding-bottom: 10px;
		// @media (max-width: 991px) {
		// 	display: none;
		// }
		&.responsive {
			display: none;
			@media (max-width: 991px) {
				display: inline-block;
			}
		}
		&:not(.responsive) {
			@media (max-width: 991px) {
				display: none;
			}
		}
		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
			@include recolor($white);
		}
		@media (max-width: 991px) {
			background-color: transparent;
			margin-right: 0px;
			img {
				@include recolor($blue);
			}
		}
	}
	.container-logo {
		background-color: $blue;
		width: $width-menu;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		flex-direction: column;
		@media (max-width: 991px) {
			width: auto;
			padding-left: 5px;
			padding-right: 5px;
			background-color: transparent;
		}
		.slogan {
			text-align: center;
			font-family: $font-family-bold;
			margin-bottom: 0px;
			@media (max-width: 991px) {
				display: none;
			}
		}
		img {
			width: 200px;
			height: 50px;
			object-fit: contain;
			// @media (min-width: 992px) {
			// 	@include recolor($white);
			// }
			@media (max-width: 991px) {
				border-radius: 8px;
			}
		}
		a.responsive {
			display: none;
			img {
				width: 60px;
			}
		}
		@media (max-width: 991px) {
			background-color: $white;
			a:not(.responsive) {
				display: none;
			}
			a.responsive {
				display: inline-block;
			}
		}
	}
	.btn-profile {
		background-color: $blue_dark !important;
		border: 0px !important;
		box-shadow: none !important;
		border-radius: 30px !important;
		color: $white !important;
		font-size: 16px;
		padding: 10px;
		outline: 0px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (min-width: 992px) {
			min-width: 150px;
			padding-left: 20px !important;
		}
		font-family: $font-family-medium;
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
		}
		@media (max-width: 991px) {
			span {
				display: none;
			}
		}
	}
	.btn-logout {
		box-shadow: none !important;
		background-color: transparent !important;
		outline: 0px !important;
		border: 0px !important;
		color: $white !important;
		font-size: 18px;
		img {
			width: 25px;
			height: 25px;
			margin-left: 10px;
			object-fit: contain;
		}
	}
}