// Table

.table-component {
	overflow: hidden;
	margin-bottom: 0px;
	@media (max-width: 767px) {
		min-width: 500px;
	}
	thead {
		border: 0px !important;
		border-bottom: 1px solid $prod-gray3;
		background-color: $blue_dark;
		tr {
			height: 1px;
		}
		th {
			height: inherit;
			vertical-align: middle;
			border: 0px !important;
			color: $white;
			font-family: $font-family-bold;
			font-size: 16px;
			padding: 10px;
			padding-left: 0px;
			padding-right: 0px;
			&:first-child {
				border-bottom-left-radius: 15px;
				left: 10px;
				.container-blue-th {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					margin-left: 10px;
				}
			}
			&:last-child {
				border-bottom-right-radius: 15px;
				padding-right: 10px;
				.container-blue-th {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
					margin-right: 10px;
				}
			}
		}
		&.thead-responsive {
			display: none;
		}
		tr {
			// border-bottom: 1px solid darken($prod-gray,20) !important;
		}
		.container-blue-th {
			background-color: $cyan2;
			padding: 10px;
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
	tbody {
		border: 0px !important;
		tr {
			border: 0px !important;
			border-bottom: 1px solid darken($prod-gray,20) !important;
			&:hover {
				background-color: darken($gray,5);
			}
			&:last-child {
				border-bottom: 0px !important;
			}
		}
		td {
			border: 0px;
			button {
				margin-right: 5px;
			}
		}
	}
	td, th, th > div {
		font-size: 13px;
	}
}

.table-component.table-color {
	cursor: pointer;
}

.no-table-data {
	font-size: 16px;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
	margin-bottom: 0px;
}

.table-container-component {
	width: 100%;
	overflow: auto;
	padding-top: 0px;
	hr {
		display: none;
	}
}

.table-container-white {
	@include shadow2();
	background-color: $white;
	border-radius: 10px;
	overflow: hidden;
	.row-title-table {
		background-color: $blue_dark;
	}
	.container-add-table {
		display: inline-flex;
		align-items: center;
		p {
			font-size: 18px;
			margin: 0px;
			color: $white;
			font-family: $font-family-semibold;
			margin-right: 10px;
		}
	}
	.add-btn-table {
		background-color: $green_light;
		border-radius: 8px;
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			@include recolor($white);
		}
	}
}

.container-title-right {
	padding: 20px;
}