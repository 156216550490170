// Collections

#admin-collections {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-datepicker {
		margin-top: 5px;
	}
}

.modal-create-reminder {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-create-reminder {
	padding: 20px;
	button[type="submit"] {
		font-family: $font-family-bold;
		background-color: $green !important;
		font-size: 16px;
		min-width: 250px;
		border-radius: 10px;
		padding: 10px;
		margin-top: 20px;
	}
	.check-container {
		display: inline-flex;
		@include noSelect();
		cursor: pointer;
		margin-bottom: 15px;
		p {
			margin: 0px;
			margin-left: 10px;
		}
		.check {
			width: 20px;
			height: 20px;
			border-radius: 5px;
			border: 1px solid $gray2;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-top: 1px;
			&.active {
				background-color: $green;
				img {
					@include recolor($white);
					width: 13px;
					height: 13px;
					object-fit: contain;
					position: absolute;
				}
			}
		}
	}
	.bold {
		font-family: $font-family-bold;
		margin-bottom: 10px;
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-paper-clip {
		width: 100%;
		button {
			width: 100%;
			border-color: darken($gray,20) !important;
			border-radius: 5px !important;
		}
	}
	.container-icon-paperclip {
		background-color: $blue_dark;
		border-top-left-radius: 5px !important;
		border-bottom-left-radius: 5px !important;
	}
	.row-modes {
		margin-top: 20px;
	}
	.container-days {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
		.item-day {
			background-color: $blue;
			border-radius: 5px;
			margin-right: 5px;
			@include shadow();
			margin-bottom: 10px;
			cursor: pointer;
			&.active {
				background-color: $green;
			}
			p {
				padding: 10px;
				margin-bottom: 0px;
				color: $white;
				font-family: $font-family-bold;
			}
		}
	}
	.image-sended {
		width: 100px;
		height: 100px;
		border-radius: 5px;
		object-fit: cover;
	}
}