// Users

#admin-users {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	td, th, th > div {
		font-size: 11px;
		p {
			margin-bottom: 3px;
		}
	}
	.status {
		font-family: $font-family-bold;
	}
	.label {
		font-family: $font-family-bold;
		margin-bottom: 0px;
		font-size: 14px;
		text-align: center;
	}
	.btn-google-maps {
		outline: 0px !important;
		box-shadow: none !important;
		padding: 0px !important;
		height: auto !important;
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
		}
	}
	.btn-valid {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 12px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
	}
	.btn-credolab {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 14px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
		margin-bottom: 15px;
	}
	.credolab-rejected {
		font-family: $font-family-bold;
		color: $danger;
	}
	.credolab-active {
		color: $green;
		font-family: $font-family-bold;
	}
}

.modal-credolab {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-credolab {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	select.form-control {
		background-color: $white !important;
	}
	.btn-save {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 10px !important;
		font-size: 18px;
		font-family: $font-family-bold;
		color: $white !important;
		border-radius: 10px;
		margin-top: 20px;
		width: 200px;
	}
}

.modal-create-user {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-create-user {
	padding: 20px;
	.paperclip-image {
		margin-bottom: 20px;
		.container-icon-paperclip {
			display: none;
		}
		.selected-image {
			width: 120px;
			height: 120px;
			object-fit: cover;
			border-radius: 60px;
		}
		button {
			padding: 0px !important;
			min-width: auto;
			border: 0px !important;
			height: auto;
		}
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	select.form-control {
		background-color: $white !important;
	}
	.label {
		font-family: $font-family-bold;
		font-size: 14px;
		margin-bottom: 5px;
	}
	.container-phone {
		display: flex;
		select {
			max-width: 100px;
			margin-right: 15px;
		}
	}
	.btn-save {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 10px !important;
		font-size: 18px;
		font-family: $font-family-bold;
		color: $white !important;
		border-radius: 10px;
		margin-top: 20px;
		width: 200px;
	}
}

.modal-users-google-maps {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-users-google-maps {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	p {
		font-family: $font-family-bold;
		color: $blue;
	}
}

.modal-users-valid {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
}

#modal-users-valid {
	padding: 20px;
	.btn-valid {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 10px !important;
		font-size: 18px;
		font-family: $font-family-bold;
		color: $white !important;
		width: 100%;
		border-radius: 10px;
		&.btn-invalid {
			background-color: $danger !important;
		}
	}
	.document_photo {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 5px;
		margin-right: 10px;
	}
	.item-document {
		display: flex;
		margin-bottom: 35px;
		align-items: center;
		p {
			margin-bottom: 0px;
			color: $blue;
			font-size: 15px;
			font-family: $font-family-bold;
		}
	}
	a {
		color: $blue !important;
		text-decoration: none !important;
	}
	.row {
		margin-top: 20px;
	}
}

.modal-view-bank {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-view-bank {
	padding: 20px;
	.btn-save {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 10px !important;
		font-size: 18px;
		font-family: $font-family-bold;
		color: $white !important;
		border-radius: 10px;
		margin-top: 20px;
		width: 200px;
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
}