// Pagination

.pagination {
	margin-top: 20px;
	.page-link, .page-item {
		text-decoration: none !important;
		outline: 0px !important;
		box-shadow: none !important;
		background-color: transparent !important;
		border: 0px !important;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: $font-family-bold;
		color: $prod-light !important;
		font-size: 12px;
	}
	.page-item.active {
		.page-link {
			background-color: $blue_dark !important;
			border-color: $blue_dark !important;
			width: 25px;
			height: 25px;
			border-radius: 12.5px;
			color: $white !important;
		}
	}
}