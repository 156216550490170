// Global Notifications

#admin-global-notifications {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-datepicker {
		margin-top: 5px;
	}
	.btn-config {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 14px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
		margin-bottom: 15px;
	}
}

.send-global-notification {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#send-global-notification {
	padding: 20px;
	padding-bottom: 0px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.btn-edit {
		background-color: $green;
		color: $white;
		font-family: $font-family-bold;
		font-size: 16px;
		margin-bottom: 20px;
		outline: 0px !important;
		box-shadow: none !important;
	}
	.check-container {
		display: inline-flex;
		@include noSelect();
		cursor: pointer;
		margin-bottom: 15px;
		p {
			margin: 0px;
			margin-left: 10px;
		}
		.check {
			width: 20px;
			height: 20px;
			border-radius: 5px;
			border: 1px solid $gray2;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-top: 1px;
			&.active {
				background-color: $green;
				img {
					@include recolor($white);
					width: 13px;
					height: 13px;
					object-fit: contain;
					position: absolute;
				}
			}
		}
	}
}

.modal-reminder-config {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-reminder-config {
	padding: 20px;
	padding-bottom: 0px;
	.btn-edit {
		background-color: $green;
		color: $white;
		font-family: $font-family-bold;
		font-size: 16px;
		margin-bottom: 20px;
		outline: 0px !important;
		box-shadow: none !important;
	}
}