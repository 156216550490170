// Accreditations

#admin-accreditations {
	padding: 20px;
	.row-tabs {
		width: 800px;
		margin: 0 auto;
		margin-bottom: 20px;
		a {
			text-decoration: none !important;
		}
	}
	.item-tab {
		width: 90%;
		margin: 0 auto;
		border-radius: 10px;
		border: 1px solid $gray2;
		background-color: $white;
		display: flex;
		padding: 15px;
		align-items: center;
		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			margin-right: 20px;
			@include recolor($blue_dark);
		}
		p {
			margin: 0px;
			font-family: $font-family-bold;
			color: $blue_dark;
		}
		&.active {
			border-color: $blue_dark;
			background-color: $blue_dark;
			img {
				@include recolor($white);
			}
			p {
				color: $white !important;
			}
		}
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	.btn-valid {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 12px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
	}
	.btn-credolab {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 14px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
		margin-bottom: 15px;
	}
}

.modal-view-accreditations {
	@include modal();
	.modal-header {
		border-top-right-radius: 10px !important;
		border-top-left-radius: 10px !important;
	}
	.modal-dialog {
		max-width: 90vw;
	}
	.modal-body {
		border-bottom-right-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
		overflow: hidden;
	}
}

#modal-view-accreditations {
	padding-bottom: 10px;
	.title {
		font-size: 16px;
		font-family: $font-family-bold;
		margin-bottom: 0px;
	}
	.subtitle {
		color: $blue;
		font-family: $font-family-bold;
		font-size: 14px;
		margin-bottom: 0px;
	}
	.header {
		background-color: $blue;
		padding: 10px;
		margin-bottom: 0px;
		color: $white;
		font-family: $font-family-bold;
		font-size: 14px;
	}
	.row, .col-8, .col-2 {
		padding: 0px;
		margin: 0px;
		p {
			margin-bottom: 0px;
			padding: 5px;
			font-size: 13px;
			&.bold {
				font-family: $font-family-bold;
				color: darken($gray2,30);
			}
		}
	}
	.container-header {
		padding: 10px;
		padding-top: 0px;
	}
	.bg-cyan {
		background-color: darken($cyan,5);
		border-bottom: 1px solid $gray2;
	}
	.bg-gray {
		background-color: $gray;
		border-bottom: 1px solid $gray2;
	}
	.name {
		color: $blue !important;
		margin-top: 5px;
	}
	.btn-valid {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 5px !important;
		font-size: 12px;
		font-family: $font-family-bold;
		color: $white !important;
		min-width: auto;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-left: 5px;
	}
	.td-week {
		padding-top: 5px;
		font-size: 11px;
		font-family: $font-family-bold;
	}
	.td-value {
		font-size: 13px;
		height: 40px;
	}
	.accreditation {
		color: $gray2;
		font-family: $font-family-bold;
		margin-top: 2px;
	}
	.td-green {
		background-color: lighten($green,30);
	}
	.td-gray {
		background-color: $gray2;
	}
	.status-payment {
		padding: 2px !important;
		font-family: $font-family-bold;
	}
}

.modal-view-config {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
}

#modal-view-config {
	padding: 20px;
	.check-container {
		display: flex;
		cursor: pointer;
		.check {
			width: 20px;
			height: 20px;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $gray3;
			img {
				width: 13px;
				height: 13px;
				object-fit: contain;
				@include recolor($white);
			}
			&.active {
				background-color: $green;
			}
		}
		p {
			margin: 0px;
			font-size: 15px;
			margin-bottom: 5px;
			margin-left: 10px;
		}
	}
	.btn-save {
		outline: 0px !important;
		box-shadow: none !important;
		background-color: $green !important;
		padding: 10px !important;
		font-size: 18px;
		font-family: $font-family-bold;
		color: $white !important;
		border-radius: 10px;
		margin-top: 20px;
		width: 200px;
	}
}