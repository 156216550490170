// San

#admin-san {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
}

.modal-create-san {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-create-san {
	padding: 20px;
	button[type="submit"] {
		font-family: $font-family-bold;
		background-color: $green_light !important;
		font-size: 20px;
		min-width: 250px;
		border-radius: 10px;
		padding: 20px;
		margin-top: 20px;
	}
	.container-gray {
		background-color: $gray;
		padding: 10px;
		p {
			margin: 0px;
			font-size: 14px;
			font-family: $font-family-bold;
			margin-top: 10px;
		}
	}
	.separator {
		width: 95%;
		background-color: $gray2;
		margin-top: 10px;
		margin-bottom: 10px;
		height: 1px;
		margin: 0 auto;
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	select.form-control {
		background-color: $white !important;
	}
	.frequency-label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	.container-checks {
		display: flex;
		margin-bottom: 15px;
		.item-check {
			display: flex;
			cursor: pointer;
			.check {
				background-color: $gray;
				width: 20px;
				height: 20px;
				border-radius: 10px;
				object-fit: contain;
				border: 1px solid $gray2;
				margin-right: 10px;
				&.active {
					background-color: $green;
				}
			}
			p {
				margin: 0px;
				margin-right: 10px;
				font-size: 14px;
			}
		}
	}
}

.modal-view-chat {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

.modal-edit-message {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-edit-message {
	min-height: 80px;
	padding: 20px;
	padding-bottom: 0px;
	.btn-edit {
		background-color: $green;
		color: $white;
		font-family: $font-family-bold;
		font-size: 16px;
		margin-bottom: 20px;
		outline: 0px !important;
		box-shadow: none !important;
	}
}

#modal-view-chat {
	padding: 20px;
	.container-messages {
		display: flex;
		flex-direction: column;
		max-height: 70vh;
		@include scroll();
		overflow-y: auto;
		.item-message {
			align-items: center;
			display: flex;
			.container-actions {
				display: flex;
				margin-bottom: 5px;
				.name {
					flex: 1;
				}
				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
					&.edit-icon {
						@include recolor($white);
					}
				}
			}
			.profile-picture {
				width: 40px;
				height: 40px;
				object-fit: cover;
				border-radius: 30px;
				margin-right: 10px;
			}
			background-color: $cyan2;
			@include shadow();
			border-radius: 10px;
			margin-bottom: 10px;
			padding: 10px;
			align-self: flex-start;
			min-width: 50%;
			max-width: 75%;
			.name {
				color: $white;
				font-family: $font-family-bold;
				font-size: 12px;
				margin-bottom: 0px;
			}
			.message {
				font-size: 12px;
				color: $white;
				margin-bottom: 0px;
				margin-bottom: 5px;
			}
			.date {
				color: $white;
				font-size: 10px;
				margin-bottom: 0px;
			}
		}
	}
	.btn-paginate {
		background-color: $blue;
		color: $white;
		font-family: $font-family-bold;
		font-size: 12px;
		margin-bottom: 20px;
		outline: 0px !important;
		box-shadow: none !important;
	}
	.no-messages {
		@include shadow();
		border-radius: 7px;
		margin: 0 auto;
		display: inline-block;
		padding: 10px;
		background-color: $cyan2;
		color: $white;
		font-size: 14px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 20px;
	}
	.container-textarea {
		display: flex;
		align-items: center;
		textarea.form-control {
			border-radius: 10px !important;
			background-color: $white !important;
			outline: 0px !important;
			box-shadow: none !important;
			border: 1px solid darken($gray,20) !important;
			font-size: 14px;
			resize: none;
			flex: 1;
		}
		img {
			
		}
		button {
			min-width: auto;
			width: auto;
			outline: 0px !important;
			box-shadow: none !important;
			padding: 0px !important;
			img {
				width: 25px;
				height: 25px;
				object-fit: contain;
				margin-left: 10px;
				@include recolor($blue);
				transition: .5s;
			}
			&:active {
				img {
					@include recolor(darken($blue,20));
				}
			}
		}
	}
}