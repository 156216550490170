// Moderators

#admin-moderators {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-datepicker {
		margin-top: 5px;
	}
}

.modal-create-moderators {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
}

#modal-create-moderators {
	button[type="submit"], button[type="button"] {
		background-color: $blue !important;
		color: $white !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		border-radius: 10px;
		width: 'auto';
		font-size: 16px;
		margin-top: 30px;
		margin-bottom: 30px;
		min-width: 200px;
	}
	.container-inputs {
		padding: 20px;
	}
	.row-buttons {
		padding: 0px !important;
		margin: 0px !important;
		margin-top: 20px !important;
		.col-md-6 {
			padding: 0px !important;
			margin: 0px !important;
		}
	}
	h3 {
		font-family: $font-family-bold;
		color: $blue;
		font-size: 20px;
		padding-left: 10px;
		padding-right: 10px;
	}
	table {
		width: 100%;
		td {
			width: 20%;
			padding-top: 5px;
			padding-bottom: 5px;
			&:first-child {
				width: 40%;
			}
		}
	}
	.td-check {
		display: flex;
		font-size: 14px;
		.checkbox-table-component {
			margin-right: 5px;
		}
	}
	.td-center {
		.checkbox-table-component {
			margin: 0 auto;
		}
	}
	#map {
		width: 100%;
		height: 300px;
	}
	.location-permission {
		margin-top: 15px;
		margin-bottom: 15px;
		p {
			text-align: center;
			background-color: darken($bg,5);
			padding: 10px;
			border-radius: 30px;
		}
	}
	#location-input {
		border-radius: 3px;
		background-color: $white !important;
		outline: 0px !important;
		box-shadow: none !important;
		font-size: 14px;
		padding: 9px !important;
		border: 1px solid darken($gray,20) !important;
		width: 100%;
		border-radius: 10px;
		margin-bottom: 20px;
	}
	.location-input-label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-cyan {
		background-color: $cyan3;
		padding: 10px;
	}
}
