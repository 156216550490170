// Collections

#admin-collections {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
		background-color: $white !important;
		height: 42px !important;
	}
	.container-datepicker {
		margin-top: 5px;
	}
	.container-blue-th {
		font-size: 11px;
	}
	td {
		font-size: 11px;
		p {
			margin-bottom: 0px;
			&.bold {
				font-family: $font-family-bold;
			}
		}
		&.bold, &.blue {
			font-family: $font-family-bold;
		}
		&.blue {
			color: $blue;
		}
	}
	label {
		font-size: 12px;
	}
}