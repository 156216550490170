// Payments

#admin-payments {
	padding: 20px;
	input.form-control, select.form-control, .container-datepicker {
		border-radius: 10px !important;
	}
	.container-datepicker {
		margin-top: 7px;
	}
	.container-name {
		 p {
		 	margin-bottom: 0px;
		 	&.bold {
		 		font-family: $font-family-bold;
		 	}
		 }
	}
}