// Datepicker

.react-datepicker-wrapper {
	display: block;
}

.container-datepicker {
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	min-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $white !important;
	border: 0px !important;
	border-radius: 10px !important;
	height: 42px;
	// margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	border: 1px solid darken($gray,20) !important;
	&.white {
		background-color: $white !important;
	}
	p {
		min-width: 100%;
		margin: 10px;
		padding-left: 35px;
		font-size: 13px;
		&.placeholder {
			color: darken($prod-gray,50);
		}
	}
	img {
		width: 20px;
		position: absolute;
		left: 10px;
	}
}

.label-datepicker {
	display: block;
}