// Commissions

#admin-commissions {
	padding: 20px;
	.container-white {
		@include shadow();
		padding: 20px;
		border-radius: 20px;
		margin: 0 auto;
		background-color: $white;
		width: 100%;
		max-width: 500px;
	}
	h3 {
		color: $blue;
		font-family: $font-family-bold;
		margin-top: 10px;
		font-size: 18px;
	}
	.container-input-days {
		display: flex;
		input {
			width: 50px;
			margin-right: 10px;
			margin-left: 10px;
			text-align: center;
		}
	}
	button {
		border-radius: 10px !important;
		font-size: 16px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: $blue !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		font-family: $font-family-bold;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
		width: 200px;
		margin-top: 10px;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}
	.container-positions {
		.container-input {
			border-bottom: 1px solid darken($gray4,10);
			padding-top: 10px;
			padding-bottom: 10px;
			&:last-child {
				border-bottom: 0px;
			}
			.form-group {
				margin-bottom: 0px;
			}
			p {
				margin: 0px;
				font-family: $font-family-bold;
				margin-top: 10px;
				font-size: 14px;
			}
		}
	}
	.image-search-input.search-left {
		width: 15px;
		top: 14px;
	}
}